<template>
  <div class="number-title">
    <div class="calculator-top animate__animated animate__pulse">
      <div class="calculator-top-title" v-if="!this.isLoading">
        <div style="position: absolute; top: 16px; margin-left: 24px" :class="_calculatorStatus === 0 ? 'calculator-top-box plus' : 'minus'" v-if="this._calculatorStatusIsVisible">
          <p :class="_calculatorStatus === 0 ? 'calculator-top-box plus' : 'minus'">
            {{
              _calculatorStatus === 0
                ? "-" +
                  Math.abs(this._calculatorStatusValue / 1000)
                    .toFixed(2)
                    .replaceAll(".", ",")
                : "+" + (this._calculatorStatusValue / 1000).toFixed(2).replaceAll(".", ",")
            }}
          </p>
        </div>
        <h3>{{ this._calculationNumber }}</h3>
      </div>
      <div v-else class="calculator-top-title animate__animated animate__heartBeat">
        <h3>...</h3>
      </div>
      <div class="calculator-top-text">
        <span>{{ this.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["title", "isLoading"],
  computed: {
    ...mapGetters(["_calculatorValue", "_calculationNumber", "_calculatorStatus", "_calculatorStatusValue", "_calculatorStatusIsVisible"]),
  },
};
</script>
