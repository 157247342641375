import { createStore } from "vuex";

const store = createStore({
  state: {
    preStep: -1,
    step: null,
    housesize: null,
    persons: null,
    houseage: null,
    heattype: null,
    eltype: null,
    elvolume: null,
    devices: null,
    elclass: null,
    ledstake: null,
    cartype: null,
    cartime: null,
    pubtransporttime: null,
    traintime: null,
    shortflight: null,
    longflight: null,
    cruise: null,
    diet: null,
    shopping: null,
    compensation: null,

    calculatorValue: '11,17',
    calculationNumber: '11,17',
    calculatorStatusValue: null,
    calculatorStatus: null,
    calculatorStatusIsVisible: null,
    result: null,
    allRequestedData: null,

  },
  getters: {
    _preStep: (state) => state.preStep,
    _step: (state) => state.step,
    _housesize: (state) => state.housesize,
    _persons: (state) => state.persons,
    _houseage: (state) => state.houseage,
    _heattype: (state) => state.heattype,
    _eltype: (state) => state.eltype,
    _elvolume: (state) => state.elvolume,
    _devices: (state) => state.devices,
    _elclass: (state) => state.elclass,
    _ledstake: (state) => state.ledstake,
    _cartype: (state) => state.cartype,
    _cartime: (state) => state.cartime,
    _pubtransporttime: (state) => state.pubtransporttime,
    _traintime: (state) => state.traintime,
    _shortflight: (state) => state.shortflight,
    _longflight: (state) => state.longflight,
    _cruise: (state) => state.cruise,
    _diet: (state) => state.diet,
    _shopping: (state) => state.shopping,
    _compensation: (state) => state.compensation,

    
    _calculatorValue: (state) => state.calculatorValue,
    _calculationNumber: (state) => state.calculationNumber,
    _calculatorStatusValue: (state) => state.calculatorStatusValue,
    _calculatorStatus: (state) => state.calculatorStatus,
    _calculatorStatusIsVisible: (state) => state.calculatorStatusIsVisible,
    _result: (state) => state.result,
    _allRequestedData: (state) => state.allRequestedData,

  },
  mutations: {
    preStepAdd: (state, payload) => (state.preStep = payload),
    stepAdd: (state, payload) => (state.step = payload),
    housesizeAdd: (state, payload) => (state.housesize = payload),
    personsAdd: (state, payload) => (state.persons = payload),
    houseageAdd: (state, payload) => (state.houseage = payload),
    heattypeAdd: (state, payload) => (state.heattype = payload),
    eltypeAdd: (state, payload) => (state.eltype = payload),
    elvolumeAdd: (state, payload) => (state.elvolume = payload),
    devicesAdd: (state, payload) => (state.devices = payload),
    elclassAdd: (state, payload) => (state.elclass = payload),
    ledstakeAdd: (state, payload) => (state.ledstake = payload),
    cartypeAdd: (state, payload) => (state.cartype = payload),
    cartimeAdd: (state, payload) => (state.cartime = payload),
    pubtransporttimeAdd: (state, payload) => (state.pubtransporttime = payload),
    traintimeAdd: (state, payload) => (state.traintime = payload),
    shortflightAdd: (state, payload) => (state.shortflight = payload),
    longflightAdd: (state, payload) => (state.longflight = payload),
    cruiseAdd: (state, payload) => (state.cruise = payload),
    dietAdd: (state, payload) => (state.diet = payload),
    shoppingAdd: (state, payload) => (state.shopping = payload),
    compensationAdd: (state, payload) => (state.compensation = payload),

    calculatorValueAdd: (state, payload) => (state.calculatorValue = payload),
    calculationNumberAdd: (state, payload) => (state.calculationNumber = payload),
    calculatorStatusValueAdd: (state, payload) => (state.calculatorStatusValue = payload),
    calculatorStatusAdd: (state, payload) => (state.calculatorStatus = payload),
    calculatorStatusIsVisibleAdd: (state, payload) => (state.calculatorStatusIsVisible = payload),
    resultAdd: (state, payload) => (state.result = payload),
    allRequestedDataAdd: (state, payload) => (state.allRequestedData = payload),

  },
});
export default store;
