import store from "@/store";
import router from "@/router";
import appAxios from "@/utils/appAxios";

export default {
  install: (app) => {
    app.mixin({
      computed: {
        //Headers for standart axios request
        // axiosConfig() {
        //   let authenticationKey = store.getters["userData/_authData"].authenticationKey;
        //   let config = {
        //     headers: {
        //       Authorization: "X-Bearer-Token " + authenticationKey,
        //     },
        //   };
        //   return config;
        // },
      },
      methods: {

        //Check URL
        async checkUrl(step) {
          let preStep = store.getters["_preStep"];
          if (preStep == null) preStep = -1;

          // console.log("checkUrl:", step);
          // console.log("preStep:", preStep);

          if (step != preStep + 1 && step - preStep != -1) {
            console.log("redirrect");
            await router.push("/calculator/start/");
          }

          store.commit("preStepAdd", step);
          store.commit("stepAdd", "preStep");
        },

        //Calculate
        async calculate(data) {
          await appAxios.post("co2/calculate-avg", data).then((res) => {
            store.commit("resultAdd", res.data);
            store.commit("allRequestedDataAdd", res.data);
            let calculatorValue = (res.data.result / 1000).toFixed(2).replaceAll(".", ",");
            store.commit("calculationNumberAdd", calculatorValue);
            store.commit("calculatorStatusValueAdd", res.data.difference);
            this.calculatorStatusValue = res.data.difference;
            if (this.calculatorStatusValue > 0) {
              this.calculatorStatus = 1;
              this.calculatorStatusIsVisible = true;
            } else if (this.calculatorStatusValue < 0) {
              this.calculatorStatus = 0;
              this.calculatorStatusIsVisible = true;
            } else if (this.calculatorStatusValue === 0) {
              this.calculatorStatusIsVisible = false;
            }
            store.commit("calculatorStatusIsVisibleAdd", this.calculatorStatusIsVisible);
            store.commit("calculatorStatusAdd", this.calculatorStatus);
          });

          // let response;
          // response = this.fetch("https://s6.tink.de/index.php/api/co2/calculate-avg", "post", data);
          // const responseResponse = () => {
          //   return response.then(async (res) => {
          //     store.commit("resultAdd", res);
          //     store.commit("allRequestedDataAdd", res);
          //     this.calculatorValue = (res.result / 1000).toFixed(2).replaceAll(".", ",");
          //     store.commit("calculationNumberAdd", this.calculatorValue);
          //     store.commit("calculatorStatusValueAdd", res.difference);
          //     this.calculatorStatusValue = res.difference;
          //     if (this.calculatorStatusValue > 0) {
          //       this.calculatorStatus = 1;
          //       this.calculatorStatusIsVisible = true;
          //     } else if (this.calculatorStatusValue < 0) {
          //       this.calculatorStatus = 0;
          //       this.calculatorStatusIsVisible = true;
          //     } else if (this.calculatorStatusValue === 0) {
          //       this.calculatorStatusIsVisible = false;
          //     }
          //     store.commit("calculatorStatusIsVisibleAdd", this.calculatorStatusIsVisible);
          //     store.commit("calculatorStatusAdd", this.calculatorStatus);
          //   });
          // };
          // await responseResponse();
        },

        //Request Action Data
        requestActionData(step) {
          return [
            {
              step: "housesize",
              value: store.getters["_housesize"],
              is_current: step === "housesize" ? 1 : 0,
            },
            {
              step: "persons",
              value: store.getters["_persons"],
              is_current: step === "persons" ? 1 : 0,
            },
            {
              step: "houseage",
              value: store.getters["_houseage"],
              is_current: step === "houseage" ? 1 : 0,
            },
            {
              step: "heattype",
              value: store.getters["_heattype"],
              is_current: step === "heattype" ? 1 : 0,
            },
            {
              step: "eltype",
              value: store.getters["_eltype"],
              is_current: step === "eltype" ? 1 : 0,
            },
            {
              step: "elvolume",
              value: store.getters["_elvolume"],
              is_current: step === "elvolume" ? 1 : 0,
            },
            {
              step: "devices",
              value: store.getters["_devices"],
              is_current: step === "devices" ? 1 : 0,
            },
            {
              step: "elclass",
              value: store.getters["_elclass"],
              is_current: step === "elclass" ? 1 : 0,
            },
            {
              step: "ledstake",
              value: store.getters["_ledstake"],
              is_current: step === "ledstake" ? 1 : 0,
            },
            {
              step: "cartype",
              value: store.getters["_cartype"],
              is_current: step === "cartype" ? 1 : 0,
            },
            {
              step: "cartime",
              value: store.getters["_cartime"],
              is_current: step === "cartime" ? 1 : 0,
            },
            {
              step: "pubtransporttime",
              value: store.getters["_pubtransporttime"],
              is_current: step === "pubtransporttime" ? 1 : 0,
            },
            {
              step: "traintime",
              value: store.getters["_traintime"],
              is_current: step === "traintime" ? 1 : 0,
            },
            {
              step: "shortflight",
              value: store.getters["_shortflight"],
              is_current: step === "shortflight" ? 1 : 0,
            },
            {
              step: "longflight",
              value: store.getters["_longflight"],
              is_current: step === "longflight" ? 1 : 0,
            },
            {
              step: "cruise",
              value: store.getters["_cruise"],
              is_current: step === "cruise" ? 1 : 0,
            },
            {
              step: "diet",
              value: store.getters["_diet"],
              is_current: step === "diet" ? 1 : 0,
            },
            {
              step: "shopping",
              value: store.getters["_shopping"],
              is_current: step === "shopping" ? 1 : 0,
            },
            {
              step: "compensation",
              value: store.getters["_compensation"],
              is_current: step === "compensation" ? 1 : 0,
            },
          ];
        },

        //Push Product
        pushProduct(url) {
          window.open(url, "_blank");
        },

        //Footer Text
        footText(data) {
          let text = null;
          if (data >= 11.17) {
            text = "Dein Fußabdruck liegt über dem Durchschnitt. Spare mit unseren Produkten CO2e ein.";
          } else if (data > 0) {
            text = "Dein Fußabdruck liegt im Durchschnitt. Spare mit unseren Produkten CO2e ein.";
          } else if (data === 0) {
            text = "Toll! Du kompensierst bereits Deinen gesamten Fußabdruck. Spare dennoch mit unseren Produkten CO2e ein.";
          }
          document.querySelector(".co2-text").innerHTML = text;
        },

        //Scroll To
        scrollTo(id) {
          document.getElementById(id).scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        },

      },
    });
  },
};
