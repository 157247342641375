<template>
  <div class="logo-container justify-content-center">
    <div class="logo">
      <a href="https://www.tink.de" target="_blank">
        <svg version="1.1" height="43" viewBox="0 0 859.81 283" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="tink_3_" transform="translate(-73.2,-38.5)" fill="#000">
            <path id="k_3_" d="m930.6 268.3-77.4-87.1 60.3-42.2c4.3-3 5.4-9 2.3-13.3-3-4.3-9-5.4-13.3-2.3l-78.4 54.9v-93.4c0-5.3-4.3-9.6-9.6-9.6s-9.6 4.3-9.6 9.6v189.8c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6v-73l13.3-9.3 78.8 88.7c1.9 2.1 4.5 3.2 7.1 3.2 2.3 0 4.5-0.8 6.3-2.4 4.2-3.6 4.5-9.6 1-13.6z"></path>
            <path id="n_3_" d="m701.5 119.5c-33.8 0-61.4 28.6-61.4 63.7v91.5c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6v-91.5c0-24.6 19-44.6 42.3-44.6s42.3 20 42.3 44.6v91.5c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6v-91.5c-0.3-35.2-27.8-63.7-61.6-63.7z"></path>
            <path id="i_3_" d="m587.3 121c-5.3 0-9.6 4.3-9.6 9.6v144.1c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6v-144.1c0-5.3-4.3-9.6-9.6-9.6z"></path>
            <path id="t_3_" d="m535 140.1c5.3 0 9.6-4.3 9.6-9.6s-4.3-9.6-9.6-9.6h-36.9v-36c0-5.3-4.3-9.6-9.6-9.6s-9.6 4.3-9.6 9.6v129.7c0 30.6 3.6 47.2 12.3 57.2 10.1 11.5 25 12.5 42 12.5h1.7c5.3 0 9.6-4.3 9.6-9.6s-4.3-9.6-9.6-9.6h-1.7c-14.5 0-23.2-0.9-27.6-5.9-5.2-5.9-7.6-20.1-7.6-44.7v-74.4z"></path>
          </g>
          <g transform="translate(-73.2,-38.5)" fill="#1998fa">
            <path d="m214.7 149.1c-8.3 0-16.1 3.2-21.9 9.1l-0.5 0.5-66.6 76.3c12.8 20.6 32.5 36.5 55.9 44.3v-21.9c0-18.9 14.8-34.2 33.1-34.2s33.1 15.4 33.1 34.2v21.9c23-7.7 42.5-23.2 55.3-43.3l-66.7-78c-5.9-5.7-13.6-8.9-21.7-8.9z"></path>
            <path
              d="m214.7 38.5c-78.2 0-141.5 63.4-141.5 141.5s63.4 141.5 141.5 141.5c78.2 0 141.5-63.4 141.5-141.5s-63.4-141.5-141.5-141.5zm-98.9 79.1c21.6-34.1 58.6-54.5 98.9-54.5s77.3 20.4 98.9 54.5c1.8 2.9 1 6.7-1.9 8.5s-6.7 1-8.5-1.9c-19.3-30.5-52.4-48.8-88.5-48.8s-69.2 18.2-88.5 48.8c-1.2 1.9-3.2 2.9-5.2 2.9-1.1 0-2.3-0.3-3.3-1-2.9-1.8-3.7-5.6-1.9-8.5zm162.6 24.6c-1.1 0.8-2.3 1.1-3.6 1.1-1.9 0-3.8-0.9-5-2.6-12.7-17.8-33.3-28.5-55.2-28.5-21.8 0-42.4 10.6-55.2 28.4-2 2.8-5.8 3.4-8.6 1.4s-3.4-5.8-1.4-8.6c15.1-21 39.4-33.5 65.2-33.5s50.2 12.6 65.2 33.6c2.1 2.9 1.4 6.8-1.4 8.7zm-63.7 154.7c-64.5 0-116.9-52.5-116.9-116.9 0-8.4 0.9-16.8 2.7-25l0.2-0.8c0.7-3.3 4-5.4 7.4-4.6 3.3 0.7 5.4 4 4.6 7.4l-0.2 0.7c-1.6 7.3-2.4 14.8-2.4 22.4 0 15.5 3.4 30.1 9.4 43.3l63.8-73c0.2-0.3 0.5-0.5 0.8-0.8 8.2-8.2 19-12.8 30.6-12.8 11.5 0 22.4 4.5 30.5 12.6l0.3 0.4 63.8 74.7c6.4-13.5 9.9-28.6 9.9-44.4 0-7.8-0.9-15.6-2.6-23.1-0.7-3.3 1.3-6.6 4.7-7.4 3.3-0.8 6.6 1.3 7.4 4.7 1.9 8.4 2.9 17.1 2.9 25.8 0 64.4-52.4 116.8-116.9 116.8z"
            ></path>
            <path d="m214.7 235.4c-11.4 0-20.8 9.8-20.8 21.9v25.2c6.7 1.4 13.6 2.1 20.8 2.1 7.1 0 14-0.7 20.8-2.1v-25.2c-0.1-12-9.4-21.9-20.8-21.9z"></path>
          </g>
        </svg>
      </a>
    </div>
  </div>
</template>
