import { createApp } from "vue";
import App from "./App.vue";
import store from "@/store";
import router from "./router";
import { createGtm } from '@gtm-support/vue-gtm';
import 'bootstrap';

//Components
import VideoBackground from 'vue-responsive-video-background-player'
import VueSmoothScroll from 'v-smooth-scroll'
import CalculatorTitle from "@/components/calculator/calculator-title";

// UI

// Styles
import '@/assets/normalize.css'
import '@/assets/css/bootstrap.css'
import '@/assets/css/app.css'
import '@/assets/css/button.css'
import '@/assets/css/chatbox.css'
import '@/assets/css/radio.css'
import '@/assets/css/checkbox.css'
import '@/assets/css/onboarding.css'
import '@/assets/css/variants.css'
import '@/assets/css/animated.css'
import '@/assets/css/result.css'
import '@/assets/css/send.css'
import '@/assets/css/desktop.css'
import '@/assets/css/modal.css'
import '@/assets/style.css'
import '@/assets/video.css'
import '@/assets/fonts/stylesheet.css'

//Custom Plugins
import customPlugins from "./mixins/customPlugins";


const app = createApp(App);

app.use(store);
app.use(router);
app.component('video-background', VideoBackground);
app.component('CalculatorTitle', CalculatorTitle);
app.use(customPlugins);
app.use(VueSmoothScroll);
app.use(
  createGtm({
    id: 'GTM-WRHJ5S2',
    enabled: true,
    debug: true,
    defer: false
  })
)

app.mount("#app");
