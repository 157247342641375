import axios from "axios";

const appAxios = axios.create({
  baseURL: "https://s6.tink.de/index.php/api",
  //baseURL: "https://172.20.10.2:8000/index.php/api",
  https: true,
//   headers: {
//     Authorization: "X-Bearer-Token " + apiToken(),
//   },
});

export default appAxios;
