import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "default" */ "@/views/HomeView"),
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import(/* webpackChunkName: "default" */ "@/views/OnboardingView"),
  },
  {
    path: "/result",
    name: "result",
    component: () => import(/* webpackChunkName: "default" */ "@/views/ResultView"),
  },
  {
    path: "/calculator",
    name: "calculator",
    component: () => import(/* webpackChunkName: "steps" */ "../views/CalculatorView"),
    children: [
      {
        path: "start",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/StartView"),
      },
      {
        path: "step-1",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step01View"),
      },
      {
        path: "step-2",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step02View"),
      },
      {
        path: "step-3",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step03View"),
      },
      {
        path: "step-4",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step04View"),
      },
      {
        path: "step-5",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step05View"),
      },
      {
        path: "step-6",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step06View"),
      },
      {
        path: "step-7",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step07View"),
      },
      {
        path: "step-8",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step08View"),
      },
      {
        path: "step-9",
        component: () => import(/* webpackChunkName: "steps" */ "../views/calculator/Step09View"),
      },
      {
        path: "step-10",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step10View"),
      },
      {
        path: "step-11",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step11View"),
      },
      {
        path: "step-12",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step12View"),
      },
      {
        path: "step-13",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step13View"),
      },
      {
        path: "step-14",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step14View"),
      },
      {
        path: "step-15",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step15View"),
      },
      {
        path: "step-16",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step16View"),
      },
      {
        path: "step-17",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step17View"),
      },
      {
        path: "step-18",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step18View"),
      },
      {
        path: "step-19",
        component: () => import(/* webpackChunkName: "stepsnext" */ "../views/calculator/Step19View"),
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: () => import(/* webpackChunkName: "default" */ "@/views/HomeView") },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const DEFAULT_TITLE = "CO2-Fußabdruck berechnen mit dem CO2 Rechner | tink";
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
