<template>
  <div class="main-container">
    <topBar></topBar>
    <router-view />
    <footerBar></footerBar>
  </div>
</template>
<script>
import topBar from "@/components/TopBar";
import footerBar from "@/components/FooterBar";

export default {
  components: {
    topBar,
    footerBar,
  },
  computed: {},
  methods: {
    reCalculateMain() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  created() {},
  mounted() {
    this.reCalculateMain();
  },
};
</script>
